



















import Vue, { PropType } from 'vue';
import ApiService from '@/services/api.service';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';

export default Vue.extend({
  name: 'DiagramAnalytics',
  props: {
    diagramType: {
      type: String as PropType<DiagramType>,
      required: true,
    },
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
  },
  data() {
    return {
      analytics: [] as any,
      columns: [
        {
          name: 'label',
          label: 'Diagram Name',
          field: 'label',
          align: 'left',
        },
        {
          name: 'versionLabel',
          label: 'Version Name',
          field: 'versionLabel',
          align: 'left',
        },
        {
          name: 'status',
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          name: 'elementCount',
          label: 'Element Count',
          field: 'elementCount',
          align: 'center',
        },
        {
          name: 'assignedAssets',
          label: 'Assigned Assets',
          field: 'assignedAssets',
          align: 'center',
        },
        {
          name: 'emptyAssets',
          label: 'Empty Assets',
          field: 'emptyAssets',
          align: 'center',
        },
        {
          name: 'uniqueAssets',
          label: 'Unique Assets',
          field: 'uniqueAssets',
          align: 'center',
        },
        {
          name: 'connectionCount',
          label: 'Connection Count',
          field: 'connectionCount',
          align: 'center',
        },
        {
          name: 'dependencyCount',
          label: 'Dependency Count',
          field: 'dependencyCount',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async redirectTo(analytic: any) {
      await this.$router.push(`/org/${this.org.id}/project/${this.project.id}/view-bit/${analytic.diagramId}/version/${analytic.versionId}`);
    },
    async loadReports() {
      try {
        const response = await ApiService.get(`/project/${this.$route.params.projectId}/analytics/${this.diagramType}`);
        this.analytics = response.results;
      } catch {
        this.analytics = [];
      }
    },
  },
  async created() {
    await this.loadReports();
  },
});
