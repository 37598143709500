













































































































































































import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import { uploadFile } from '@/util/upload';
import { bulkAssetCreateRows } from '@/util/assets';
import SelectModellingState from '@/views/projects/modellingState/SelectModellingState.vue';
import ApiService from '@/services/api.service';
import { formatDate } from '@/util/date';
import { createDownloadLink } from '@/bridge/print/printUtils';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'BulkCreateAssets',
  components: {
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    SelectModellingState,
  },
  mixins: [formSubmit],
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    request(): FormWrapperRequest {
      const callback = async () => {
        this.csvError = await uploadFile(
          'assets',
          'post',
          this.file,
          { label: this.uploadLabel, formId: this.formId, modellingStateId: this.modellingStateId },
          `/project/${this.projectId}/asset/bulk`,
          (event: any) => {
            this.uploadProgress[0] = event.loaded / event.total;
            this.$forceUpdate();
          },
        );
      };
      return { callback };
    },
    // 250 KBs
    maxFileSizeInBytes() {
      return 250 * 1e+3;
    },
  },
  data() {
    return {
      file: new File([], '') as File,
      uploadLabel: '',
      error: '',
      hasError: false,
      errorReady: false,
      csvError: [] as any[],
      csvErrorMap: {} as Record<number, any>,
      uploadProgress: {} as Record<string, number>,
      modellingStateId: '',
      formId: '',
      loading: false,
    };
  },
  methods: {
    csvErrorText(e: any) {
      const col = e.column ? `, column ${e.column}` : '';
      return `${e.label || ''} (row ${e.row || 0}${col}): ${e.reason || ''}`;
    },
    clearKeepingLabel() {
      const tmp = this.uploadLabel;
      this.clearAll();
      this.uploadLabel = tmp;
    },
    clearAll() {
      this.csvError = [];
      this.csvErrorMap = {};
      this.error = '';
      this.hasError = false;
      this.file = new File([], '');
      this.uploadLabel = '';
      this.uploadProgress = {};
      this.loading = false;
    },
    open() {
      this.formId = this.$route.params.formId;
      this.clearAll();
      (this.$refs.modal as any).open();
    },
    fileSizeInMb(size: number): string {
      return `${(size / (1024 * 1024)).toFixed(2)}Mb`;
    },
    async downloadTemplate() {
      let csvContent = '';
      let label = '';
      // if we have a form associated, we need to fetch its template for the user here
      if (this.formId && this.formId.length > 0) {
        try {
          csvContent = await ApiService.get(`/project/${this.projectId}/forms/${this.formId}/template`);
          const resource = await ApiService.get(`/project/${this.projectId}/forms/${this.formId}`);
          label = `${resource.label}_`;
        } catch {
          this.error = 'Could not fetch the template for this form';
        }
      } else {
        csvContent = `data:text/csv;charset=utf-8,${bulkAssetCreateRows.map((e) => e.join(',')).join('\n')}`;
      }
      const encodedUri = encodeURI(csvContent);

      createDownloadLink(`${label || 'default_'}asset_template.csv`, encodedUri);
    },
    async downloadErrorLog() {
      await this.file.text().then((content: string) => {
        const fileContent = content.split('\r\n');
        const csvContent = `data:text/csv;charset=utf-8,${fileContent
          .map((e: any, index: number) => (this.csvErrorMap[index + 1] ? `${e},"${this.csvErrorText(this.csvErrorMap[index + 1])}"` : e))
          .join('\n')}`;
        const encodedUri = encodeURI(csvContent);
        createDownloadLink('import_errors.csv', encodedUri);
      });
    },
    hasCsvErrors(): boolean {
      return this.csvError && this.csvError.length > 0;
    },
    async updateDataList() {
      this.error = '';
      this.uploadProgress[0] = 0;
      if (this.uploadLabel === '') {
        const filename = this.file.name.split('.')[0];
        this.uploadLabel = `${filename.split(' ').join('_')}_${formatDate(Date.now(), true)}`;
      }
      this.csvError = [];
      this.csvErrorMap = {};
      this.errorReady = false;
    },
    notifyRejection() {
      this.error = 'Please upload a valid csv file smaller than 250 KBs (with less than 5,000 entries)';
    },
    getSortedErrors() {
      // for some reason, rows are treated as strings rather than numbers
      const sortAlphaNum = (a: string, b: string) => a.localeCompare(b, 'en', { numeric: true });
      return Object.keys(this.csvErrorMap).sort(sortAlphaNum);
    },
    async onSubmit() {
      if (!this.file || this.file.name.length === 0) {
        this.error = 'Please upload a valid csv file';
        return;
      }
      this.loading = true;
      this.error = '';
      this.csvError = [];
      this.csvErrorMap = {};
      this.errorReady = false;

      await this.submit(this.request, this.$refs.form);
      this.loading = false;

      if (this.hasCsvErrors()) {
        this.csvError.forEach((value) => {
          const index: number = +value.row;
          this.csvErrorMap[index] = value;
        });

        this.errorReady = true;
        return;
      }

      if (this.hasError) {
        return;
      }

      (this.$refs.modal as any).close();
      this.clearAll();

      this.notify('Assets added successfully');
      this.$emit('submit');
    },
  },

});
