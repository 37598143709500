





















































import Vue, { PropType, VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import {
  ConnectorResource,
  AssetResource,
  ResourceType,
  AssetResourceRequest,
  ConnectorResourceRequest,
} from '@/types/resource';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import SelectLayer from '@/components/fields/SelectLayer.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';
import SelectConnectorAssets from '@/views/resources/SelectConnectorAssets.vue';

function getDefaultElementResourceModel(resource?: AssetResource) {
  const model: AssetResourceRequest = {
    type: ResourceType.ASSET,
    label: '',
    description: '',
    layer: 0,
  };

  if (resource) {
    model.label = resource.label;
    model.description = resource.description;
    model.layer = resource.layer;
  }

  return model;
}

function getDefaultConnectorResourceModel(type: ResourceType, resource?: ConnectorResource) {
  const model: ConnectorResourceRequest = {
    type,
    label: '',
    description: '',
    from: '',
    to: '',
  };

  if (resource) {
    model.label = resource.label;
    model.description = resource.description;
    model.from = resource.from.id;
    model.to = resource.to.id;
  }

  return model;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ResourceForm',
  components: {
    SelectConnectorAssets,
    EditBtn,
    SelectLayer,
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    FieldDescription,
  },
  mixins: [formSubmit],
  props: {
    selectedResourceId: String,
    resourceType: {
      type: String as PropType<ResourceType>,
      default: ResourceType.ASSET,
    },
    resource: {
      type: Object as PropType<AssetResource|ConnectorResource>,
      required: false,
    },
  },
  computed: {
    disableLayerSelect(): boolean {
      // The selectedResourceId prop is passed when we open the resource from
      // the canvas view. At that point, the asset is already assigned,
      // so we should not be able to change the layer
      return !!this.selectedResourceId || (this.resource && this.resource.usedByTotal > 0);
    },
    isAssetResource(): boolean {
      return this.resourceType === ResourceType.ASSET;
    },
    isEditForm(): boolean {
      return this.resource && !!this.resource.label;
    },
    resourceName(): string {
      switch (this.resourceType) {
        case ResourceType.CONNECTION:
          return 'Connection';
        case ResourceType.DEPENDENCY:
          return 'Dependency';
        default:
          return 'Asset';
      }
    },
    colLabel(): string {
      return this.resourceName || ' Name';
    },
    title(): string {
      const action = this.isEditForm ? 'Edit' : 'New';
      return `${action} ${this.resourceName}`;
    },
    request(): FormWrapperRequest {
      const { projectId } = this.$route.params;

      const req: FormWrapperRequest = {
        config: {
          data: this.model,
          method: 'post',
          url: `/project/${projectId}/asset`,
        },
      };

      if (this.resource && this.isEditForm && req.config) {
        req.config.method = 'put';
        req.config.url = `/project/${projectId}/asset/${this.resource.id}`;
      }

      return req;
    },
  },
  data() {
    return {
      model: {} as Record<string, any>,

      filter: {
        layerTo: '',
        layerFrom: '',
      },
    };
  },
  methods: {
    async open() {
      this.resetValidation();
      this.setDefaultModel();
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.error = '';

      if (!this.isAssetResource && (!this.model.to || !this.model.from)) {
        this.error = 'From and To assets are required';
        return;
      }

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      this.$emit('submit');
      (this.$refs.modal as any).close();
      this.notify(`${this.resourceName} ${this.isEditForm ? 'edited' : 'created'}`);
    },
    setDefaultModel() {
      if (this.resourceType === ResourceType.ASSET) {
        this.model = getDefaultElementResourceModel(this.resource as AssetResource);
      } else {
        this.model = getDefaultConnectorResourceModel(
          this.resourceType,
          this.resource as ConnectorResource,
        );
      }
    },
    setAssets({ kind, id }: { kind: 'to'|'from'; id: string; }) {
      this.error = '';
      this.model[kind] = id;
    },
  },
});
