









































import Vue, { PropType } from 'vue';
import { AssetResource, ResourceType } from '@/types/resource';
import SelectAsset from '@/views/assets/SelectAsset.vue';
import { getLayerFilters } from '@/views/resources/getLayerFilters';
import ApiService from '@/services/api.service';
import ApiError from '@/components/ApiError.vue';
import Btn from '@/components/buttons/Btn.vue';

export default Vue.extend({
  name: 'SelectConnectorAssets',
  components: {
    ApiError,
    SelectAsset,
    Btn,
  },
  props: {
    disable: Boolean,
    model: {
      type: Object,
      default() {
        return {
          from: '',
          to: '',
        };
      },
    },
    fieldErrors: {
      type: Object,
      default() {
        return {
          from: '',
          to: '',
        };
      },
    },
    resourceType: {
      type: String as PropType<ResourceType>,
      default: ResourceType.ASSET,
    },
  },
  computed: {
    defaultResource(): ResourceType {
      return ResourceType.ASSET;
    },
    disableReverseBtn(): boolean {
      const hasAssets = !!this.toAsset && !!this.fromAsset;
      return this.disable || !hasAssets;
    },
    isDependency(): boolean {
      return this.resourceType === ResourceType.DEPENDENCY;
    },
  },
  data() {
    return {
      error: '',
      fromAsset: null as null | AssetResource,
      toAsset: null as null | AssetResource,
      filter: {
        layerTo: '',
        layerFrom: '',
      },
    };
  },
  methods: {
    resetAssets() {
      this.filter.layerFrom = '';
      this.filter.layerTo = '';

      this.fromAsset = null;
      this.toAsset = null;

      this.$emit('reset');
    },
    onSelectAsset(selected: AssetResource, kind: 'from' | 'to') {
      this.filter = getLayerFilters(this.resourceType, this.fromAsset, this.toAsset);

      this.$emit('select', {
        kind,
        id: selected ? selected.id : null,
      });
    },
    reverseDependency() {
      if (this.fromAsset && this.toAsset) {
        const tmp = this.toAsset;
        this.toAsset = this.fromAsset;
        this.fromAsset = tmp;
        this.onSelectAsset(this.toAsset, 'to');
        this.onSelectAsset(this.fromAsset, 'from');
      }
    },
  },
  async created() {
    const { to, from } = this.model;
    const { projectId } = this.$route.params;

    if (to && from) {
      try {
        this.fromAsset = await ApiService.get(`/project/${projectId}/assets/${from}`);
        this.toAsset = await ApiService.get(`/project/${projectId}/assets/${to}`);
        this.filter = getLayerFilters(this.resourceType, this.fromAsset, this.toAsset);
      } catch {
        this.error = 'Could not load assets. Please try again later';
      }
    }
    //  @todo set default assets
  },
});
