






























import Vue, { PropType } from 'vue';
import ApiService from '@/services/api.service';
import { ResourceType } from '@/types/resource';

type SelectOption = {
  id: string;
  label: string;
};

export default Vue.extend({
  name: 'SelectAsset',
  props: {
    required: Boolean,
    layerFilter: String,
    errorMessage: String,
    resourceType: {
      type: String as PropType<ResourceType>,
      required: true,
    },
    value: {
      type: Object,
      required: false,
    },
    extraParams: Object,
    // for connector assets, the same asset can't be selected for both fields
    not: {
      type: Object,
      required: false,
    },
  },
  computed: {
    searchUrl(): string {
      return `project/${this.$route.params.projectId}/assets/info?resourceType=${this.resourceType}`;
    },
  },
  data() {
    return {
      options: [] as SelectOption[],
    };
  },
  methods: {
    isEqual(opt: any): boolean {
      return Object(opt) === opt && this.not ? opt.id === this.not.id : false;
    },
    validation(selected: string | string[]) {
      if (this.required && !selected) {
        return 'Asset is required';
      }

      return true;
    },
    async loadOptions() {
      const response = await ApiService.get(
        this.searchUrl,
        {
          perPage: 99,
          layer: this.layerFilter,
          ...this.extraParams,
        },
      );

      if (!response.results || !response.results.length) {
        this.options = [];
        return;
      }

      this.options = response.results;
    },
    async filterFn(val: string, update: any, abort: any) {
      try {
        const response = await ApiService.get(
          this.searchUrl,
          {
            perPage: 99,
            search: val,
            layer: this.layerFilter,
            ...this.extraParams,
          },
        );

        update(() => {
          if (!response.results || !response.results.length) {
            this.options = [];
            return;
          }
          const needle = val.toLowerCase();
          this.options = response.results
            .filter((v: SelectOption) => v.label.toLowerCase()
              .includes(needle));
        });
      } catch (e) {
        if (abort) {
          abort();
        }
      }
    },
  },
  async created() {
    const response = await ApiService.get(this.searchUrl, { perPage: 99, ...this.extraParams });

    if (!response.results || !response.results.length) {
      this.options = [];
      return;
    }

    this.options = response.results;
  },
});
