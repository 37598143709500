




































import Vue from 'vue';
import { ProjectModellingsState } from '@/types/project';
import ApiService from '@/services/api.service';

export default Vue.extend({
  name: 'SelectModellingState',
  props: {
    multiple: Boolean,
    required: Boolean,
    errorMessage: String,
    value: {
      type: [String, Array],
      required: false,
    },
  },
  computed: {
    label() {
      if (this.multiple) {
        return 'Select modelling states';
      }
      return 'Select modelling state';
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
  },
  data() {
    return {
      options: [] as ProjectModellingsState[],
    };
  },
  methods: {
    validation(selected: string | string[]) {
      if (this.required) {
        if (this.multiple && (!selected || !selected.length)) {
          return 'Modelling state is required';
        }
        if (!this.multiple && !selected) {
          return 'Modelling state is required';
        }
      }

      return true;
    },
    async filterFn(val: string, update: any, abort: any) {
      try {
        const response = await ApiService.get(`/project/${this.projectId}/modelling-states/info`, {
          perPage: 99,
          search: val,
        });

        update(() => {
          if (!response.results || !response.results.length) {
            this.options = [];
            return;
          }
          const needle = val.toLowerCase();
          this.options = response.results
            .filter((v: ProjectModellingsState) => v.label.toLowerCase()
              .includes(needle));
        });
      } catch {
        abort();
      }
    },
  },
  async created() {
    const response = await ApiService.get(
      `/project/${this.projectId}/modelling-states/info`,
      { perPage: 99 },
    );

    if (!response.results || !response.results.length) {
      this.options = [];
      return;
    }

    this.options = response.results;
  },
});
