import { render, staticRenderFns } from "./BulkCreateAssets.vue?vue&type=template&id=20629824&"
import script from "./BulkCreateAssets.vue?vue&type=script&lang=ts&"
export * from "./BulkCreateAssets.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QFile,QIcon,QTooltip,QSpace});
