import { ResourceType, AssetResource } from '@/types/resource';
import { layerUtils } from '@/bridge/base/layerInfo';

function buildLayerFilter(resource: AssetResource): string {
  if (!layerUtils.isLayer(resource.layer)) {
    return '';
  }

  // Connection can go to the current layer,
  // and adjacent layers
  let layerFilter = `${resource.layer}`;

  if (resource.layer > 0) {
    layerFilter = `${layerFilter},${resource.layer - 1}`;
  }
  if (resource.layer < 5) {
    layerFilter = `${layerFilter},${resource.layer + 1}`;
  }

  return layerFilter;
}

export function getLayerFilters(
  type: ResourceType,
  fromElementResource: null|AssetResource,
  toElementResource: null|AssetResource,
) {
  const filter = {
    layerTo: '',
    layerFrom: '',
  };

  // Dependencies don't have a layer to/from restriction
  if (type === ResourceType.DEPENDENCY) {
    return filter;
  }

  if (fromElementResource && !Number.isNaN(fromElementResource.layer)) {
    filter.layerTo = buildLayerFilter(fromElementResource);
  }

  if (toElementResource && !Number.isNaN(toElementResource.layer)) {
    filter.layerFrom = buildLayerFilter(toElementResource);
  }

  return filter;
}
