


















































import Vue, { PropType } from 'vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import SelectLayer from '@/components/fields/SelectLayer.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import LayerBadge from '@/components/LayerBadge.vue';
import { ResourceType } from '@/types/resource';
import { TableColumn } from '@/types/list';
import ResourceForm from '@/views/resources/ResourceForm.vue';
import BulkCreateAssets from '@/views/assets/BulkCreateAssets.vue';

export default Vue.extend({
  name: 'ListResources',
  components: {
    BulkCreateAssets,
    ResourceForm,
    LayerBadge,
    ListWithFilter,
    SelectLayer,
    DescriptionCol,
  },
  props: {
    resourceType: {
      type: String as PropType<ResourceType>,
      default: ResourceType.ASSET,
    },
    params: {
      type: [Object, Function],
      required: false,
    },
    listTitle: {
      type: String,
      required: false,
    },
    emitRowClick: Boolean,
  },
  computed: {
    isElementResource(): boolean {
      return this.resourceType === ResourceType.ASSET;
    },
    colName(): string {
      if (this.resourceType === ResourceType.CONNECTION) {
        return 'Connection Name';
      }
      if (this.resourceType === ResourceType.DEPENDENCY) {
        return 'Dependency Name';
      }
      return 'Asset Name';
    },
    paramsObj(): any {
      let paramsObj = this.params;
      if (typeof this.params === 'function') {
        paramsObj = this.params(this.$route);
      }
      return paramsObj;
    },
    qp(): string {
      let qp = '';
      if (this.params) {
        const p = this.paramsObj;

        qp = '?';

        Object.keys(p).forEach((key: string) => {
          let value = p[key];
          if (Array.isArray(value)) {
            value = value.join(',');
          }

          if (qp === '?') {
            qp = `${qp}${key}=${value}`;
          } else {
            qp = `${qp}&${key}=${value}`;
          }
        });
      }
      if (qp === '') {
        qp = `?resourceType=${this.resourceType}`;
      } else {
        qp = `${qp}&resourceType=${this.resourceType}`;
      }
      return qp;
    },
    searchUrl(): string {
      return `project/${this.projectId}/assets/info${this.qp}`;
    },
    exportUrl(): string {
      return `project/${this.projectId}/assets/export${this.qp}`;
    },
    viewUrl(): string {
      if (this.emitRowClick) {
        return '';
      }
      return `/org/${this.orgId}/project/${this.projectId}/${this.resourceType}`;
    },
    deleteUrl(): string {
      return `/project/${this.projectId}/asset/remove`;
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    extraColumns(): TableColumn[] {
      const columns = [
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'usedByTotal',
          label: '#Uses',
          field: 'usedByTotal',
          align: 'left',
          sortable: true,
        },
      ];

      if (this.resourceType === ResourceType.ASSET) {
        columns.splice(1, 0, {
          name: 'layer',
          label: 'Layer',
          field: 'layer',
          align: 'center',
          sortable: false,
        });
        columns.splice(3, 0, {
          name: 'importLabel',
          label: 'Import Label',
          field: 'importLabel',
          align: 'center',
          sortable: false,
        });
      }

      return columns;
    },
  },
  data() {
    return {
      layers: [],
    };
  },
  methods: {
    onLayerFilter(v: number[] | null, applyFilter: any) {
      applyFilter(v, { layer: v ? v.join(',') : null });
    },
  },
});
